.mentions {
  position: absolute;
  background-color: #fff;
  width: 266px;
  max-height: 130.5px;
  padding: 12px 0px 0px 0px;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid rgba(224, 224, 224, 1);
  z-index: 999;
  left: 20px;

  .mention {
    width: 90%;
    margin: 0 auto;
    min-height: 36px;
    padding: 6px;
    gap: 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: rgba(224, 208, 232, 1);
    }

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.41px;
      text-align: left;
    }
  }
}
