.dropdown {
  position: relative;
  display: inline-block;
  width: calc(100% - 14px);
  border-radius: 4px;
  border: 1px solid #f0eff0;
  background: #f1f1f1;
  margin: 10px 7px;
}

.dropdown_content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 5px;
  background: var(--frame);
  border-radius: 0px 0px 4px 4px;
}

.draggable_item {
  padding: 5px;

  span {
    font-size: 15px;
    font-weight: 400;
    opacity: 0.8;
    color: var(--icon-colour);
    padding: 4px;
    cursor: pointer;
    border-radius: 5px;
    transition: 300ms;
  }
}

.dropdown_button {
  font-size: 14px;
  background: transparent;
  padding: 2px 10px;
  border: none;
  font-weight: 500;
  color: var(--icon-colour);
  display: flex;
  align-items: center;

  &:hover {
    color: var(--match);
    transition: 300ms;
  }
}
