.settings_page_title {
  color: var(--match);
  font-size: 24px;
  font-weight: 700;
  margin-left: 30px;
  margin-bottom: 16px;
  margin-top: 20px;
}

.settings_box {
  max-width: calc(100% - 30px);
  width: fit-content;
  height: fit-content;
  box-shadow: 0px 7px 12px 1px rgba(0, 0, 0, 0.1);
  background-color: var(--dark-white);
  border-radius: 10px;
  margin: 20px 0px 30px 30px;
  padding: 25px;
  position: relative;

  .subtitles {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    align-items: center;

    p {
      margin: 0;
    }

    .learn_setting {
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.settings_desc {
  font-size: 12px;
  margin: 4px;
  margin-left: 6px;
  color: var(--icon-colour);
}

.settings_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  width: 500px;
  max-width: 100%;
  background-color: var(--contrast);
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;

  h6 {
    font-size: 16px;
    color: #868686;
    margin: 2px 0px;
  }

  h5 {
    font-size: 16px;
    color: var(--icon-colour);
    margin: 2px 0px;
  }

  p {
    margin: 0;
    display: inline-block;
  }

  .info_status {
    margin-left: 11px;
    border-radius: 5px;
    background-color: var(--dark-white);
    display: inline-block;
    padding: 4px 8px;
    font-size: 12px;
    width: fit-content;
  }

  .gray_button {
    padding: 5px 12px;
    background-color: var(--icon-colour);
    border: none;
    box-shadow: none;
    border-radius: 4px;
    color: var(--contrast);
    transition: 200ms;
    cursor: pointer;
  }

  .gray_button:hover {
    transition: 200ms;
    opacity: 0.9;
  }

  .small_gray_button {
    cursor: pointer;
    padding: 4px 12px;
    background-color: var(--bg-light);
    font-size: 13px;
    border-radius: 5px;
    font-weight: 500;
  }

  .small_gray_button:hover {
    transition: ease-in-out 200ms;
    opacity: 0.7;
  }

  .or_button {
    background-color: var(--contrast);
    border: 1px solid var(--separator);
    color: var(--icon-colour);
    width: calc(100% - 24px);
    margin: 0 12px;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 12px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .or_button:hover {
    transition: ease-in-out 300ms;
    opacity: 0.7;
  }

  .separator {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 20px 0;

    .line_separator {
      flex-grow: 1;
      height: 1px;
      background-color: var(--separator);
      margin: 0 10px;
    }

    .text_separator {
      white-space: nowrap;
      padding: 0 10px;
      color: var(--separator);
    }
  }

  .radio_label {
    margin-left: 18px;
    width: -webkit-fill-available;
    max-width: 100%;
    font-size: 14px;
    color: var(--icon-colour);
    align-self: center;
  }

  .custom_checkbox {
    width: 24px;
    height: 24px;
    min-width: 24px;
    background-color: var(--contrast);
    border: 2px solid var(--purple);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--contrast);

    svg {
      font-size: 1rem;
    }
  }

  .check_boxes {
    display: flex;
    margin: 12px 0px 0px 12px;
    border: 1px solid #f1f1f1;
    padding: 10px;
    border-radius: 8px;
    width: -webkit-fill-available;
  }

  .custom_checkbox.checked {
    background-color: var(--purple);
  }

  .radio_selector_large {
    display: flex;
    align-items: start;
    width: -webkit-fill-available;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    margin: 12px 0px 0px 12px;
    gap: 8px;
    padding: 10px 10px;
  }

  .radio_selector {
    display: flex;
    align-items: start;
    width: -webkit-fill-available;
    border-radius: 8px;
    margin: 12px 0px 0px 12px;
    border: 1px solid #f1f1f1;
    padding: 8px 5px;
  }

  input[type='radio'] {
    appearance: none;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border: 2px solid var(--purple);
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    background-color: var(--contrast);
    margin: 8px 10px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--contrast);
    }
  }

  input[type='radio']:checked {
    &:after {
      background-color: var(--purple);
    }
  }
}

.top_color_account {
  background: #f0d4e0;
  width: 100%;
  max-width: 550px;
  height: 160px;
  top: 0px;
  left: 0px;
  z-index: 1;
  position: absolute;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
}

.a_info_container {
  display: flex;
  z-index: 2;
  position: relative;
  margin-top: 70px;
  margin-bottom: 20px;

  .name_account {
    font-size: 24px;
  }

  .username_account {
    color: #868686;
    margin-top: -5px;
    font-size: 12px;
    font-weight: 500;
  }

  .edit_profile:hover {
    transition: 200ms;
    opacity: 0.9;
  }
}

.purple_settings_button {
  background-color: var(--purple);
  border: none;
  align-self: center;
  position: absolute;
  margin-top: 12px;
  right: 0px;
  padding: 7px 30px;
  border-radius: 10px;
  color: var(--contrast);
  transition: 200ms;
  cursor: pointer;
}

.purple_settings_button:hover {
  transition: ease-in-out 200ms;
  opacity: 0.9;
}
