.radio_container {
  display: flex;
  width: 500px;
  max-width: 100%;
  background-color: var(--contrast);
  margin-top: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 2px solid #8aaee9;
  padding: 12px;

  input[type='radio'] {
    transform: scale(1.5);
    margin-top: 8px;
    margin-left: 8px;
    position: absolute;
    cursor: pointer;
  }

  label {
    margin-left: 40px;
    display: flex;
    flex-direction: column;
  }
}

.option_title {
  font-size: 16px;
  font-weight: 600;
  color: var(--icon-colour);
}

.option_description {
  font-size: 14px;
  color: var(--icon-colour);
  opacity: 0.7;
}

.radio_container input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid var(--ocean);
  border-radius: 50%;
  outline: none;
  background-color: var(--contrast);
}

.radio_container input[type='radio']:checked {
  background-color: var(--contrast);
}

.radio_container input[type='radio']:checked::after {
  content: '';
  display: block;
  margin: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--ocean);
}

//customSwitch.scss colour overide
label {
  .switch_container.active {
    background-color: var(--ocean) !important;
  }

  .switch_icon {
    color: var(--ocean) !important;
  }

  .close_icon {
    color: #b6b6b6 !important;
  }
}
