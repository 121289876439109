.hidden {
  visibility: hidden;
}

.channel_bar_content_for_messaging {
  overflow: visible !important;
  overflow-x: hidden;
}

.input-group-for-messaging {
  align-items: flex-start;
  margin-top: -32px;
  padding-bottom: 10px;
  z-index: 0;
  border-bottom: 1px solid var(--whitesmoke-white);
}
