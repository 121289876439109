.form {
  position: relative;
}

.toolbar {
  display: flex;
  background-color: #f5f5f5;
  width: auto;
  border-radius: 6px 0px 0px 6px;
  border: 1px 0px 0px 1px;
}

.menu {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 5px;
  margin-right: 5px;
  width: fit-content;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 5px;
}

pre {
  background-color: #f4f4f4;
  width: 100%;
  border-radius: 5px;
  overflow-x: auto;
}

.undefined {
  margin-right: 7px !important;
}

code {
  font-family: 'Courier New', Courier, monospace;
}

.spanLine {
  background-color: #d9d9d9;
  height: 24px;
  width: 1px;
  display: inline-block;
  vertical-align: bottom;
}

.wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  margin-top: 6px;

  .span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0 5.33px;
    border-radius: 50%;
    background-color: #f5f5f5;
    z-index: 999;

    > div {
      position: relative;

      :first-child {
        position: absolute;
        top: -460px;
        left: -39px;
      }
    }
  }

  .editor {
    width: 100%;
    margin: 10px 0px 10px 10px;
    display: flex;
    align-items: center;
    outline: none;
    flex-wrap: wrap;
    max-height: 250px;
    overflow-y: scroll;
  }
}

.sendButton {
  background-color: var(--purple) !important;
  border-radius: 10px !important;
  padding: 0 12px !important;
  gap: 6px;
  font-size: 14px !important;
  color: var(--contrast) !important;
  height: 32px !important;

  &:hover {
    background-color: var(--purple);
  }
}

.blockButton {
  cursor: pointer;
  height: 24px !important;
  width: 24px !important;
  border-radius: 100%;
  color: var(--close-icon);
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg,
  path {
    fill: var(--close-icon);
  }
}

.mentionEl {
  font-size: 14px;
  line-height: 16.41px;
  background-color: #e0e0e0;
  padding: 2px 4px;
  border-radius: 4px;
  color: blue;
  cursor: pointer;

  .uploadOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }

  .blockButton:hover {
    background: var(--contrast);
    transition: 150ms;
    color: var(--match);
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
  }
}
