.header_search_bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-height: 65px;

  @media screen and (max-width: 600px) {
    justify-content: center;
  }

  .logo_img {
    width: 50px;
    display: inline-block;
    margin: 0px 10px;

    @media screen and (max-width: 600px) {
      width: 60px;
      margin-left: 0;
    }
  }

  .search_box {
    @media screen and (max-width: 600px) {
      width: 80%;
      max-width: 100%;
      overflow: hidden;
      display: inline-block;
    }
  }

  .profile_view_container {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
