.body_support_page {
  background-color: var(--dark-white);
  max-height: 100vh;
  overflow: auto;

  .purple_container_support {
    background-color: var(--purple);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;

    width: 100%;
    height: 260px;

    h3 {
      font-size: 24px;
      font-weight: 600;
      color: white;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      color: white;

      margin-top: 5px;
    }

    .search_bar_support {
      input {
        width: 406px;
        height: 46px;
        border: var(--purple);
        border-radius: 30px;
        padding-left: 20px;
        margin-top: 15px;
      }
    }
  }

  h5 {
    font-size: 32px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
    font-weight: 600;
  }

  .help_topic {
    display: flex;

    .topic_container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 80px;
      grid-row-gap: 32px;

      .topic_card_1 {
        grid-area: 1 / 1 / 2 / 2;
        display: flex;
        width: 300px;
        height: 90px;
        align-items: center;
        margin-left: 120px;
        margin-bottom: 32px;

        .card_support_info {
          margin-left: 32px;

          h6 {
            font-size: 20px;
            font-weight: 550;
          }

          p {
            font-size: 16px;
            color: var(--icon-colour);
          }
        }
      }

      .topic_card_2 {
        grid-area: 2 / 1 / 3 / 2;
        display: flex;
        width: 300px;
        height: 90px;
        align-items: center;
        margin-left: 120px;
        margin-bottom: 32px;

        .card_support_info {
          margin-left: 32px;

          h6 {
            font-size: 20px;
            font-weight: 550;
          }

          p {
            font-size: 16px;
            color: var(--icon-colour);
          }
        }
      }

      .topic_card_3 {
        grid-area: 3 / 1 / 4 / 2;
        display: flex;
        width: 300px;
        height: 90px;
        align-items: center;
        margin-left: 120px;
        margin-bottom: 32px;

        .card_support_info {
          margin-left: 32px;

          h6 {
            font-size: 20px;
            font-weight: 550;
          }

          p {
            font-size: 16px;
            color: var(--icon-colour);
          }
        }
      }

      .topic_card_4 {
        grid-area: 1 / 2 / 2 / 3;
        display: flex;
        width: 300px;
        height: 90px;
        align-items: center;
        margin-left: 120px;
        margin-bottom: 32px;

        .card_support_info {
          margin-left: 32px;

          h6 {
            font-size: 20px;
            font-weight: 550;
          }

          p {
            font-size: 16px;
            color: var(--icon-colour);
          }
        }
      }

      .topic_card_5 {
        grid-area: 2 / 2 / 3 / 3;
        display: flex;
        width: 300px;
        height: 90px;
        align-items: center;
        margin-left: 120px;
        margin-bottom: 32px;

        .card_support_info {
          margin-left: 32px;

          h6 {
            font-size: 20px;
            font-weight: 550;
          }

          p {
            font-size: 16px;
            color: var(--icon-colour);
          }
        }
      }

      .topic_card_6 {
        grid-area: 3 / 2 / 4 / 3;
        display: flex;
        width: 300px;
        height: 90px;
        align-items: center;
        margin-left: 120px;
        margin-bottom: 32px;

        .card_support_info {
          margin-left: 32px;

          h6 {
            font-size: 20px;
            font-weight: 550;
          }

          p {
            font-size: 16px;
            color: var(--icon-colour);
          }
        }
      }

      .topic_card_7 {
        grid-area: 1 / 3 / 2 / 4;
        display: flex;
        width: 300px;
        height: 90px;
        align-items: center;
        margin-left: 120px;
        margin-bottom: 32px;

        .card_support_info {
          margin-left: 32px;

          h6 {
            font-size: 20px;
            font-weight: 550;
          }

          p {
            font-size: 16px;
            color: var(--icon-colour);
          }
        }
      }

      .topic_card_8 {
        grid-area: 2 / 3 / 3 / 4;
        display: flex;
        width: 300px;
        height: 90px;
        align-items: center;
        margin-left: 120px;
        margin-bottom: 32px;

        .card_support_info {
          margin-left: 32px;

          h6 {
            font-size: 20px;
            font-weight: 550;
          }

          p {
            font-size: 16px;
            color: var(--icon-colour);
          }
        }
      }

      .topic_card_9 {
        grid-area: 3 / 3 / 4 / 4;
        display: flex;
        width: 300px;
        height: 90px;
        align-items: center;
        margin-left: 120px;
        margin-bottom: 32px;

        .card_support_info {
          margin-left: 32px;

          h6 {
            font-size: 20px;
            font-weight: 550;
          }

          p {
            font-size: 16px;
            color: var(--icon-colour);
          }
        }
      }
    }
  }

  .need_support {
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;

    .need_support_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 80px;
      width: 615px;
      height: 170px;

      h5 {
        font-size: 38px;
      }

      p {
        font-size: 16px;
        color: var(--icon-colour);
        text-align: center;
        margin-top: -20px;
      }

      .button_contact {
        background-color: var(--purple);
        border: var(--purple);
        color: #ffff;
        font-size: 20px;
        border-radius: 10px;
        width: 205px;
        margin-top: 15px;
      }
    }
  }

  .questions_support_container {
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;

    .questions_container {
      width: 600px;
      height: 470px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h5 {
        font-size: 40px;
        font-weight: 700;
      }

      p {
        font-size: 16px;
        color: var(--icon-colour);
        margin-top: -10px;
      }

      .questions_support {
        .faq_item {
          background-color: var(--contrast);
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;

          .faq_header {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            align-items: center;

            h6 {
              font-size: 24px;
            }
          }

          .faq_answer {
            margin-top: 12px;
          }
        }
      }
    }
  }
}
