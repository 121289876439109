.error_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: 'Roboto';
}

.subtitles_error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h6 {
    font-size: 36px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    color: var(--gray-scale-light);
  }
}

.error_sidebar_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 950px;
}

.error_sidebar_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 200px;
}

.error_sidebar_subtitles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  h6 {
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 600;
  }

  p {
    text-align: center;
    font-size: 13px;
    margin-bottom: 1px;
    font-weight: 500;
    color: #a8a8a8;
  }

  span {
    color: var(--ocean);
    font-size: 14px;
    font-weight: 500;
  }
}
