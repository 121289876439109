.messages_bar {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;

  .img_container {
    margin-right: 10px;
    position: relative;
  }

  .channel_bar_content {
    .button_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;

      button {
        color: var(--purple);
        min-width: 100px;
        height: 40px;
        background: none;
        border-radius: 12px;
        border: 2px solid var(--purple);
        transition: ease-in-out 300ms;
      }

      button:hover {
        background: var(--light-purple);
        opacity: 0.8;
      }
    }

    .list_container {
      .friends_list {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .friend_container {
          display: flex;
          background-color: #f9f9f9;
          border-radius: 50px 0px 0px 50px;
          align-items: center;

          .text_container {
            color: var(--icon-colour);
            font-weight: 500;
            font-size: 16px;
            margin-left: 3px;

            p {
              font-weight: 400;
              font-size: 12px;
              color: #aeaeae;
            }
          }
        }
      }
    }
  }
}
