.url_outer_box {
  padding: 2px;
  height: 30px;
  width: 100%;
  background: var(--contrast);
  opacity: 0.8;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0px -2px 0px 0px var(--light-white);
}

.url_box {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 100%;
  background: var(--dark-white);
  font-weight: 600;
  vertical-align: top;
  font-size: 10px;
  padding: 5px 7px 0px 7px;
  width: 32px;
  border: var(--contrast);
  display: inline-block;
}
