.to_do_list_items {
  width: 250px;
  height: 105px;
  padding: 10px;
  margin-top: 12px;
  background-color: var(--contrast);
  border-radius: 10px;
}

.to_do_select {
  display: flex;
  align-items: center;

  .custom_checkbox {
    opacity: 0;
    position: absolute;

    &:checked + .checkbox_custom_label {
      display: inline-block;

      &::after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        background-color: var(--purple);
        margin: 2px 1px 1px 2px;
      }
    }
  }

  .checkbox_custom_label {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid var(--purple);
    border-radius: 5px;
    cursor: pointer;
  }

  span {
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
  }
}

.to_do_description {
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 10px;
  font-size: 12px;
  color: var(--white-a8);
}
