.input_section {
  z-index: 10;
  outline: #f1f1f1 solid 1px;
  border-radius: 10px;
  background-color: var(--contrast);
  padding: 10px;
  margin: 0px 30px;
  font-family: 'Roboto';
  position: relative;
}

.message_wrapper {
  height: 100%;
  border-top-left-radius: 10px;
  user-select: text;

  .message_interface {
    width: 100%;
    height: 100%;
    border-radius: inherit;

    .center_box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 30px 0px;
      height: 100%;

      .message_section {
        width: 100%;
        height: 100%;
        border-radius: 0;
        overflow-y: scroll;
        padding-bottom: 80px;
        padding: 0px 60px;
        padding-bottom: clamp(0px, 8%, 40px);

        .one_message {
          margin-bottom: 25px;

          &:last-child {
            margin-bottom: 0px;
          }

          .message_info {
            display: flex;
            flex-direction: row;
            align-items: center;

            .user_name {
              font-weight: 600;
              margin-left: 1%;
            }
          }

          .message_context {
            margin-top: 10px;
            white-space: pre-wrap;

            .mentioned {
              color: var(--icon-colour);
              cursor: pointer;
              display: inline-block;
              background: var(--mention-color);
              padding-left: 2px;
              padding-right: 2px;
              border-radius: 2px;
              text-decoration: none;
            }

            .mentioned:hover {
              background-color: var(--dark-grey);
              color: var(--contrast);
            }

            .quote_block {
              display: flex;
              flex-direction: row;
              align-items: stretch;

              .color_box {
                background-color: var(--dark-grey);
                width: 5px;
                border-radius: 10px;
                margin-right: 10px;
              }
            }

            p {
              margin: 0;
            }
          }

          .reactions_row {
            display: flex;
            align-items: center;

            .reactions_container {
              border: 1px solid var(--purple);
              border-radius: 20px;
              padding: 5px;
              margin-right: 12px;
              background-color: #f5eafa;

              .reaction img {
                width: 20px;
                height: 20px;
                margin-left: 5px;
                margin-right: 5px;
              }
            }
          }
        }

        .user_container {
          position: fixed;
          top: 0;
          left: 0;
          transform: translateX(-50%) translateY(-50%);
          z-index: 1000;
        }
      }
    }
  }
}

.mentionMessage {
  font-size: 14px;
  line-height: 16.41px;
  background-color: #e0e0e0;
  padding: 2px 4px;
  border-radius: 4px;
  color: blue;
  cursor: pointer;
  position: relative;

  > div {
    display: none;
  }

  &:hover {
    > div {
      display: block;
      top: 20px;
    }
  }
}
